body {
  background-color: #300350;
  color: #F9AC53;
}

a:link, a:visited {
  color: #F62E97;
  text-decoration: none;
}

a:hover, a:active {
  color: #E93479;
  text-decoration: underline;
}

a.button:link, a.button:visited {
  background-color: #F62E97;
  color: #153CB4;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
}

a.button:hover, a.button:active {
  background-color: #E93479;
  text-decoration: none;
}

div.box {
  border-color: #94167F;
  border-width: 1px;
  border-style: solid;
}

/*
 Russian Violet (#300350), Mardi Gras (#94167F), Cerise Pink (#E93479), Rajah (#F9AC53), Persian Rose (#F62E97) and Persian Blue (#153CB4)
 */